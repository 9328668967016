import React from "react"
import Layout from "../components/layout"

const About = () => {
  return (
    <Layout>
      <section className="hero is-medium has-bg-img">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                About Mail Advertising Bureau
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5">
        <div className="container pt-5 px-3">
          <p className="my-3 is-size-4">
            The Mail Advertising Bureau is a direct mail and fulfillment company
            that specializes in customized solutions to mailing and fulfillment
            challenges.
          </p>
          <p className="my-3 is-size-4">
            Originally formed in 1936, The Mail Advertising Bureau has been
            under the same ownership since 1973. With over a half century of
            combined management experience in the mailing industry, as well as
            many long term employees, we offer the know how to bring complex
            projects in on time and under budget.
          </p>
          <p className="my-3 is-size-4">
            The Mail Advertising Bureau is a family business, and like all good
            families, we know the importance of strong relationships. We strive
            to foster these relationships with each other, our co-workers, the
            USPS, our vendors and, of course, our clients. It is our belief that
            through these relationships we can produce the most cost-effective
            solutions for each of our clients’ projects. So please, check out
            the services we offer.
          </p>
          <p className="my-3 is-size-4">
            If you don’t see something you need, give us a call anyway. We love
            the challenge.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default About
